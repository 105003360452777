import { ChangePasswordSchema, PasswordChangeRequest } from "schemas/password.schema";
import { SpinButton } from "@components/form/SpinButton";
import { useChangePassword } from "@queries/auth.queries";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import styles from "./PasswordSettings.module.scss";

export function PasswordSettings() {
  const changePassword = useChangePassword();

  const { formState, register, handleSubmit, reset } = useForm<PasswordChangeRequest>({
    resolver: zodResolver(ChangePasswordSchema),
    reValidateMode: "onChange",
  });

  const handlePasswordChange = handleSubmit((data) => {
    changePassword.mutate(data, {
      onSettled: () => reset(),
    });
  });

  return (
    <div className={styles.container}>
      <form onSubmit={handlePasswordChange}>
        <div className="form-group mb-0">
          <label htmlFor="oldPassword">Old Password</label>
          <input
            type="password"
            className="form-control"
            disabled={changePassword.isLoading}
            autoComplete="off"
            {...register("oldPassword")}
          />
          <small className="form-text text-danger">{formState.errors.oldPassword?.message}</small>
        </div>

        <div className="form-group mt-2 mb-0">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            className="form-control"
            disabled={changePassword.isLoading}
            autoComplete="off"
            {...register("newPassword")}
          />
          <small className="form-text text-danger">{formState.errors.newPassword?.message}</small>
        </div>

        <div className="form-group mt-2 mb-0">
          <label htmlFor="newPasswordRepeat">Repeat New Password</label>
          <input
            type="password"
            className="form-control"
            disabled={changePassword.isLoading}
            autoComplete="off"
            {...register("newPasswordRepeat")}
          />
          <small className="form-text text-danger">{formState.errors.newPasswordRepeat?.message}</small>
        </div>

        <SpinButton
          type="submit"
          className="btn-default mt-3"
          loading={changePassword.isLoading}
          disabled={changePassword.isLoading}
        >
          Change
        </SpinButton>
      </form>

      <div className="mt-3">
        {changePassword.isSuccess && <>Your password has been changed.</>}
        {changePassword.isError && (
          <span className="text-danger">
            The password change was unsuccessful. Please ensure your password contains the required complexity and is
            not your previous password.
          </span>
        )}
      </div>
    </div>
  );
}
