import * as api from "api/tokens";
import { ApiError, LoginCredentials } from "@entities";
import { AxiosError } from "axios";
import { getConfiguration } from "api/configurations";
import { PasswordChangeRequest } from "schemas/password.schema";
import { requestPasswordReset, resetPassword } from "api/resets";
import { ServiceClient } from "@utils";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useConfiguration } from "./configuration.queries";

export function useChangePassword() {
  return useMutation((request: PasswordChangeRequest) => api.changePassword(request));
}

export function useLogin(savePassword: boolean, withBarcode?: boolean) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(async (credentials: LoginCredentials) => {
    try {
      const response = await api.login(credentials);
      const token = response.data;
      ServiceClient.setToken(token, !savePassword);

      const config = await getConfiguration();
      queryClient.setQueryData("configurations", config);

      if (withBarcode) {
        navigate("/rbi");
      } else if (response.status === 202) {
        navigate("/login/reset");
      } else if (config.coordinator?.isAep) {
        navigate("/datasheets");
      } else if (config.qualifier) {
        navigate("/certificates");
      } else if (config.coordinator) {
        navigate("/rbi");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new ApiError(error.response?.status);
      }
    }
  });
}

export function useRequestPasswordReset() {
  return useMutation((userId: string) => requestPasswordReset(userId));
}

export function useResetPassword(resetToken?: string) {
  const config = useConfiguration();
  const navigate = useNavigate();

  return useMutation(async (newPassword: string) => {
    try {
      await resetPassword(newPassword, resetToken);

      if (ServiceClient.isAuthenticated()) {
        if (config.data?.coordinator?.isAep) {
          navigate("/datasheets");
        } else if (config.data?.qualifier) {
          navigate("/certificates");
        } else if (config.data?.coordinator) {
          navigate("/rbi");
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new ApiError(error.response?.status);
      }
    }
  });
}
