import { Configuration, CustomVersion } from "@entities";
import { Link, Outlet } from "react-router-dom";
import { useConfiguration } from "@queries/configuration.queries";

export function isCustomVersion(version: CustomVersion, config: Configuration) {
  switch (version) {
    case "AEP":
      return config?.coordinator?.isAep;
    case "Paralloy":
      return config?.coordinator?.isParalloy;
    case "Hitachi":
      return config?.coordinator?.isHitachi;
    default:
      throw new Error(`Unknown version: ${version}`);
  }
}

export function VersionCheck({ version }: { version: CustomVersion }) {
  const { isLoading, data: config } = useConfiguration();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (config && isCustomVersion(version, config)) {
    return <Outlet />;
  }

  return (
    <p>
      This page requires the {version} version. Click{" "}
      <Link className="link-primary" to="/">
        here
      </Link>{" "}
      to go back.
    </p>
  );
}
