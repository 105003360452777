import axios, { AxiosError, AxiosRequestConfig, CreateAxiosDefaults, InternalAxiosRequestConfig } from "axios";
import { Page, ServiceClient } from "@utils";
import { PaginatedResult, Token } from "@entities";

const options: CreateAxiosDefaults = {
  baseURL: "/api/",
  withCredentials: true,
};

const instance = axios.create(options);
const instanceWithoutInterceptors = axios.create(options);

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use((x) => x, errorInterceptor);

function requestInterceptor(config: InternalAxiosRequestConfig) {
  const token = ServiceClient.getToken();
  if (token.accessToken) {
    config.headers.authorization = `bearer ${token.accessToken}`;
  }
  return config;
}

async function errorInterceptor(error: AxiosError) {
  const status = error.response ? error.response.status : null;
  if (status !== 401) {
    return Promise.reject(error);
  }

  const token = ServiceClient.getToken();
  if (!token.userIds || !token.refreshToken) {
    Page.redirect();
    return Promise.reject(error);
  }

  if (await reauthenticate(token, 1)) {
    const originalRequest = error.config;
    if (originalRequest) {
      return instance.request(originalRequest);
    }
  }
}

function reauthenticate(token: Token, attempts: number) {
  const payLoad = {
    userIds: token.userIds,
    refreshToken: token.refreshToken,
    grantType: "RefreshToken",
  };

  return api
    .authenticate(payLoad)
    .then((response) => {
      ServiceClient.setToken(response.data, ServiceClient.isSession());
      return true;
    })
    .catch(() => {
      if (attempts > 2) {
        ServiceClient.clearToken();
        Page.redirect();
      } else {
        attempts++;
        reauthenticate(token, attempts);
      }
    });
}

export const api = {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return instance.get<T>(url, config).then((res) => res.data);
  },
  post<T>(url: string, payload?: object, config?: AxiosRequestConfig): Promise<T> {
    return instance.post<T>(url, payload, config).then((res) => res.data);
  },
  put<T>(url: string, payload?: object): Promise<T> {
    return instance.put<T>(url, payload).then((res) => res.data);
  },
  delete<T>(url: string, payload?: object): Promise<T> {
    return instance.delete<T>(url, payload).then((res) => res.data);
  },
  authenticate(payload?: object) {
    const token = ServiceClient.getToken();

    let headers = {};
    if (token.accessToken) {
      headers = { Authorization: `Bearer ${token.accessToken}` };
    }

    return instanceWithoutInterceptors.post<Token>("tokens", payload, headers);
  },
  getPaginated<T>(url: string, params?: object): Promise<PaginatedResult<T>> {
    return instance.get<T>(url, params).then((res) => ({
      results: res.data,
      total: Number(res.headers["x-total-count"]),
      page: Number(res.headers["x-current-page"]),
    }));
  },
};
