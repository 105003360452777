import * as React from "react";
import { faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useResetPassword } from "@queries/auth.queries";
import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react";

export function ResetPassword() {
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("");
  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get("rt");
  const resetRequest = useResetPassword(resetToken ?? "");

  function handleReset(e: React.FormEvent) {
    e.preventDefault();
    resetRequest.mutate(newPassword);
  }

  return (
    <div className="about-area space__bottom--r120">
      <div className="container">
        <div className="row align-items-center row-25">
          <div className="col-md-6 order-2 order-md-1">
            <div className="about-content">
              <div className="section-title ">
                <h2 className="section-title__title">Password reset</h2>
              </div>

              <form onSubmit={handleReset}>
                <p>
                  Your password should be at least seven characters long. To make it stronger, use upper and lower case
                  letters, numbers, and symbols like ! &quot; ? $ % ^ & )
                </p>
                <div className="about-content__text space__bottom--40">
                  <div className="form-floating has-feedback has-feedback-left mb-2">
                    <input
                      type="password"
                      className="form-control"
                      id="new-password"
                      required={true}
                      placeholder="Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      autoComplete="off"
                    />
                    <label htmlFor="new-password">Password</label>
                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                  </div>
                  <div className="form-floating has-feedback has-feedback-left mb-2">
                    <input
                      type="password"
                      className="form-control"
                      id="new-password-confirm"
                      required={true}
                      placeholder="Confirm new password"
                      value={newPasswordRepeat}
                      onChange={(e) => setNewPasswordRepeat(e.target.value)}
                      autoComplete="off"
                    />
                    <label htmlFor="new-password-confirm">Confirm new password</label>
                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                  </div>
                </div>

                {resetRequest.isSuccess && (
                  <p className="alert alert-success">
                    Your password was changed. Please click{" "}
                    <Link to="/login" className="link-primary">
                      Here
                    </Link>{" "}
                    to log in again.
                  </p>
                )}

                {resetRequest.isError && (
                  <p className="alert alert-danger">
                    <span>
                      The reset was unsuccessful. Please ensure your password contains the required complexity
                      and is not your previous password.
                    </span>
                  </p>
                )}

                <button type="submit" disabled={resetRequest.isLoading} className="btn btn-default">
                  {resetRequest.isLoading && (
                    <span>
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </span>
                  )}{" "}
                  Reset my password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
