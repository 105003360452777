import { ErrorBoundary } from "react-error-boundary";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Outlet, useLocation } from "react-router";
import { SideMenu } from "./SideMenu";
import { useConfiguration } from "@queries/configuration.queries";

export function BaseLayout() {
  const location = useLocation();
  const config = useConfiguration();

  const showSideMenu = window.location.href.indexOf("/login") === -1;

  return (
    <>
      <Header />
      {showSideMenu && <SideMenu />}

      <div className="has-nav-container">
        <div id="body" className="main-row">
          <ErrorBoundary fallback={<p>An unknown error occurred.</p>} key={location.pathname}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>

      <Footer version={config.data?.version} />
    </>
  );
}
