import { CommentsDialog, SaveChangesDialog } from "../../../common/Dialogs";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InspectorContext } from "../reducer";
import { PageButton, PageControls } from "../../../common/PageControls";
import { useContext } from "react";
import { useCoordinatorMenu } from "@queries/menu.queries";
import { useGetInspections, useSave } from "../queries";
import { useModal } from "hooks";

export function MainInterface() {
  const { state, updateState } = useContext(InspectorContext);
  const { isLoading } = useGetInspections(state.welds.map((x) => x.weldId));

  const save = useSave();
  const modal = useModal();
  const defects = useCoordinatorMenu("DefectType");

  if (isLoading) {
    return <p>Loading welds...</p>;
  }

  const isWeldRow = (index: number) =>
    index === 0 || state.inspections[index].weldId !== state.inspections[index - 1].weldId;

  const handleFieldChanged = (index: number, field: string, value: string | boolean | File) => {
    const inspections = [...state.inspections];
    inspections[index] = { ...inspections[index], [field]: value };

    updateState({ type: "Inspections", payload: inspections });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld No.</th>
              <th>Joint Type</th>
              <th>Technique</th>
              {state.inspections.some((x) => x.drawingId) && <th>Weld Map</th>}
              <th>Accept</th>
              <th>Reject</th>
              <th>Defect</th>
              <th>Photo</th>
              <th>QN</th>
            </tr>
          </thead>
          <tbody>
            {state.inspections.map((row, index) => (
              <tr key={index}>
                <th>{isWeldRow(index) && row.weldNumber}</th>
                <td>{row.jointType}</td>
                <td>{row.technique}</td>
                {state.inspections.some((x) => x.drawingId) && (
                  <td>
                    {row.drawingId && (
                      <a
                        href={`drawing/${row.drawingId}?weldId=${row.weldId}`}
                        target="_blank"
                        className="link-primary"
                      >
                        View
                      </a>
                    )}
                  </td>
                )}
                <td>
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`result${index}`}
                    checked={row.accept === true}
                    onChange={() => handleFieldChanged(index, "accept", true)}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`result${index}`}
                    checked={row.accept === false}
                    onChange={() => handleFieldChanged(index, "accept", false)}
                  />
                </td>
                <td>
                  <select
                    id="defect"
                    className="form-select"
                    value={row.defect ?? ""}
                    onChange={(e) => handleFieldChanged(index, e.currentTarget.id, e.currentTarget.value)}
                    disabled={row.accept !== false}
                  >
                    <option></option>
                    {defects.data?.map((defect, index) => (
                      <option key={index}>{defect}</option>
                    ))}
                  </select>
                </td>
                <td>
                  {isWeldRow(index) && (
                    <>
                      <label htmlFor={`attachment${index}`}>
                        <FontAwesomeIcon icon={faCamera} role="button" title="Select" />{" "}
                        {row.attachment && <span>+1</span>}
                      </label>
                      <input
                        type="file"
                        id={`attachment${index}`}
                        className="d-none"
                        onChange={(e) =>
                          e.currentTarget.files && handleFieldChanged(index, "attachment", e.currentTarget.files[0])
                        }
                      />
                    </>
                  )}
                </td>
                <td>
                  {isWeldRow(index) && (
                    <a
                      role="button"
                      title="Comments"
                      onClick={() =>
                        modal.show(
                          <CommentsDialog
                            comment={row.comment}
                            onAccept={(comment: string) => handleFieldChanged(index, "comment", comment)}
                          />
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faCommentDots} />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageControls showBack>
        <PageButton
          onClick={() => modal.show(<SaveChangesDialog onAccept={() => save.mutate(state.inspections)} />)}
          loading={save.isLoading}
        >
          Save and Finish
        </PageButton>
      </PageControls>
    </>
  );
}
