import { ApiError, LoginCredentials } from "@entities";
import { faLock, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useLogin, useRequestPasswordReset } from "@queries/auth.queries";
import { useState } from "react";

export * from "./Logout";
export * from "./ScanBadge";
export * from "./ResetPassword";

export function Login() {
  const [credentials, setCredentials] = useState<LoginCredentials>({ grantType: "Password", userId: "", password: "" });
  const [savePassword, setSavePassword] = useState(false);

  const loginRequest = useLogin(savePassword);
  const resetPasswordRequest = useRequestPasswordReset();

  function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    resetPasswordRequest.reset();
    loginRequest.mutate(credentials);
  }

  function handleResetPassword(e: React.FormEvent) {
    e.preventDefault();
    loginRequest.reset();
    resetPasswordRequest.mutate(credentials.userId ?? "");
  }

  return (
    <div id="body" className="about-area space__bottom--r120">
      <div className="container">
        <div className="row align-items-center row-25">
          <div className="col-md-6 order-2 order-md-1">
            <div className="about-content">
              <div className="section-title space__bottom--25">
                <h3 className="section-title__sub">Welcome</h3>
                <h2 className="section-title__title">Access your documents</h2>
              </div>
              <form onSubmit={handleLogin}>
                <div className="about-content__text mb-4">
                  <div className="form-floating has-feedback has-feedback-left mb-2">
                    <input
                      className="form-control"
                      name="userId"
                      required
                      placeholder="Username"
                      value={credentials.userId}
                      onChange={(e) => setCredentials((prev) => ({ ...prev, userId: e.target.value }))}
                      autoComplete="off"
                      autoFocus
                    />
                    <label htmlFor="userId">Username</label>
                    <FontAwesomeIcon className="form-control-feedback" icon={faUser} />
                  </div>
                  <div className="form-floating has-feedback has-feedback-left mb-2">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      required
                      placeholder="Password"
                      value={credentials.password}
                      onChange={(e) => setCredentials((prev) => ({ ...prev, password: e.target.value }))}
                      autoComplete="off"
                    />
                    <label htmlFor="password">Password</label>
                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <span className="link">
                    <a href="#" onClick={handleResetPassword}>
                      <span>Forgot password?</span>
                    </a>
                  </span>
                  <span className="link">
                    <Link to="/login/scan" className="ms-3">
                      <span>Scan badge</span>
                    </Link>
                  </span>
                </div>

                <div className="mt-3">
                  {loginRequest.error instanceof ApiError && (
                    <p className="alert alert-danger mt-3">
                      {loginRequest.error.status === 401 && <>Your username and password did not match our records.</>}
                      {loginRequest.error.status === 500 && (
                        <>An unexpected error occurred when trying to log you in.</>
                      )}
                    </p>
                  )}

                  {resetPasswordRequest.isError && !credentials.userId && (
                    <p className="alert alert-danger mt-3">You must enter a username to reset your password.</p>
                  )}

                  {resetPasswordRequest.isSuccess && (
                    <p className="alert alert-success mt-3">
                      If a matching account was found an email has been sent to allow you to reset your password.
                    </p>
                  )}

                  <button id="login_btn" type="submit" disabled={loginRequest.isLoading} className="btn btn-default">
                    {loginRequest.isLoading && (
                      <span>
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </span>
                    )}{" "}
                    Log in
                  </button>

                  <label className="customcheck login d-lg-block">
                    Remember me
                    <input
                      type="checkbox"
                      name="rememberMe"
                      checked={savePassword}
                      onChange={(e) => setSavePassword(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
