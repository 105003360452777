import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export function Logout() {
  const navigate = useNavigate();
  const DELAY_SECONDS = 5;

  useEffect(() => {
    setTimeout(() => navigate("/login"), DELAY_SECONDS * 1000);
  }, []);

  return (
    <div>
      <p>Logout successful.</p>
      <p>
        You will be redirected in {DELAY_SECONDS} seconds. Click{" "}
        <Link to="/login" className="link-primary">
          Here
        </Link>{" "}
        if you do not want to wait.
      </p>
    </div>
  );
}
